import { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { CircledNumber } from '@components/ui/CircledNumber/CircledNumber';
import { SpanHeadingS } from '@custom-components/CustomerReview';
import { useCustomerPublicGetCustomerRatings } from '@dc/hooks';
import { useFormatter } from '@i18n';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { CustomerReviewRendering } from '@sitecore/types/manual/CustomerReview';
import { Bleed, Box, Card, Grid, Heading, Hidden, Image, Stack, Stretch, Text, TextLink } from '@sparky';
import { StarIcon, StarStrokeIcon } from '@sparky/icons';

const CustomerReview: FC<CustomerReviewRendering> = ({ fields, params }) => {
  const Link = useLinkComponent();
  const { data } = useCustomerPublicGetCustomerRatings(
    fields?.getLiveRatingDataOption?.value === true ? undefined : null,
  );
  const { format, number } = useFormatter();

  const ratingNumber = fields?.getLiveRatingDataOption?.value
    ? data?.averageScore
    : Number(fields?.ratingNumber?.value);
  const starRating = ratingNumber && Math.round(ratingNumber / 2);

  const headingLevel = params?.headingLevel ?? 'h2';

  return (
    <Grid columns={{ md: '2', lg: '3' }} gap="16">
      <Grid.Item>
        <Stretch height={true}>
          <Stack alignY={{ sm: 'justify' }}>
            <Stack gap="6">
              {fields.title?.value && (
                <Heading as={headingLevel} size={{ initial: 'S', md: 'M' }} color="inherit">
                  {wrap(fields.title)}
                </Heading>
              )}
              {fields.content?.value && <Text size={{ initial: 'BodyM', md: 'BodyL' }}>{wrap(fields.content)}</Text>}
              <SpanHeadingS>
                {ratingNumber && starRating && (
                  <>
                    {number.oneFractionDigit(ratingNumber).replace('.', ',')}{' '}
                    <Box paddingLeft="4" as="span">
                      {[...Array(starRating)].map((_, i) => (
                        <Box key={i} paddingX="2" as="span">
                          <StarIcon color="iconReview" size="large" />
                        </Box>
                      ))}
                      {[...Array(5 - starRating)].map((_, i) => (
                        <Box key={i} paddingX="2" as="span">
                          <StarStrokeIcon color="iconReview" size="large" />
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </SpanHeadingS>
              {fields.ratingSubText?.value && fields.link?.value?.href && (
                <Text size={{ initial: 'BodyS' }}>
                  {format(fields?.ratingSubText?.value, { numberOfReviews: data?.ratingsAmount || '' })}{' '}
                  <Link
                    editable={fields.link.editable}
                    linkType={fields.link.value.linktype}
                    linkValue={fields.link.value}>
                    <TextLink>{fields.link.value.text}</TextLink>
                  </Link>
                </Text>
              )}
            </Stack>
            {fields.image?.value?.src && (
              <Box paddingTop="6">
                {wrap(fields.image, <Image width="96px" src={fields.image.value.src} alt={fields.image.value.alt} />)}
              </Box>
            )}
          </Stack>
        </Stretch>
      </Grid.Item>
      {fields.items.map((item, index) => {
        const { fields } = item;
        const review = (
          <Bleed top="8" key={index}>
            <Stretch>
              <Grid.Item>
                <Stretch>
                  <Grid gridTemplateColumns="auto auto 24px" gridTemplateRows="32px auto">
                    <Grid.Item gridColumn="1/-1" gridRow="2">
                      <Stretch>
                        <Card>
                          <Box padding={{ initial: '5', md: '10' }} paddingTop="10">
                            <Stack gap="6">
                              {fields.content?.value && (
                                <Text size={{ initial: 'BodyM', md: 'BodyL' }}>
                                  {wrap(fields.content, <RichText html={fields.content.value} />)}
                                </Text>
                              )}
                              {fields.title?.value && (
                                <Text size={{ initial: 'BodyS', md: 'BodyM' }} color="textLowEmphasis">
                                  {wrap(fields.title)}
                                </Text>
                              )}
                            </Stack>
                          </Box>
                        </Card>
                      </Stretch>
                    </Grid.Item>
                    {fields.ratingNumber?.value && (
                      <Grid.Item gridColumn="2" gridRow="1/-1">
                        <CircledNumber>{wrap(fields.ratingNumber)}</CircledNumber>
                      </Grid.Item>
                    )}
                  </Grid>
                </Stretch>
              </Grid.Item>
            </Stretch>
          </Bleed>
        );

        return index === 1 ? (
          <Hidden below="lg" key={index}>
            {review}
          </Hidden>
        ) : (
          review
        );
      })}
    </Grid>
  );
};

export default CustomerReview;
